
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin, AuthMixin, DebounceMixin, BAIconsMixin } from '../../mixins';
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';

import {
	Bold,
	Italic,
	Underline,
	Strike,
	Link,
	Heading,
	History,
	Blockquote,
	ListItem,
	BulletList,
	OrderedList,
} from 'tiptap-extensions';

import {
	mdiUndo,
	mdiRedo,
	mdiFormatBold,
	mdiFormatItalic,
	mdiFormatUnderline,
	mdiFormatStrikethrough,
	mdiFormatListNumbered,
	mdiFormatQuoteOpen,
	mdiLink,
	mdiText,
	mdiSend,
	mdiFormatHeader1,
	mdiFormatParagraph,
} from '@mdi/js';
/**
 * Tiptap documentation: https://tiptap.dev/docs/guide/editor.html
 * 
 * tiptap-extensions: https://tiptap.dev/docs/api/extensions.html
 * 
 * Tiptap 2 will be written in TypeScript. For now there aren't type defs unfortunately
 * 
 * https://blog.ueber.io/post/our-plan-for-tiptap-2/
 */
@Component({
	components: {
		EditorContent,
		EditorMenuBar,
	}
})
export default class TextEditor extends Mixins(AuthMixin, DebounceMixin, VuetifyMixin, BAIconsMixin) {
	mdiUndo = mdiUndo;
	mdiRedo = mdiRedo;
	mdiFormatBold = mdiFormatBold;
	mdiFormatItalic = mdiFormatItalic;
	mdiFormatStrikethrough = mdiFormatStrikethrough;
	mdiFormatListNumbered = mdiFormatListNumbered;
	mdiFormatUnderline = mdiFormatUnderline;
	mdiLink = mdiLink;
	mdiText = mdiText;
	mdiSend = mdiSend;
	mdiFormatQuoteOpen = mdiFormatQuoteOpen;
	mdiFormatHeader1 = mdiFormatHeader1;
	mdiFormatParagraph = mdiFormatParagraph;

	@Prop({ default: false, type: Boolean }) disabled!: boolean;
	@Prop({ default: "" }) value!: string;
	input(value: string): void{
		this.value = value;
		this.$emit('input', value);
	}

	/**
	 * How to get selected text? #369
	 * https://github.com/ueberdosis/tiptap/issues/369
	 */
	updateSelection(): void{
		this.debounceCallback('updateSelection', () => {
			this.selection = this.editor.state.doc.textBetween(this.editor.state.selection.from, this.editor.state.selection.to, ' ')
		}, 200);
	}
	selection: string = "";
	get Selection(): string{
		return this.selection;
	}

	get IconColor(): string{
		return this.getColor('baColorGray3');
	}
	get IconActiveColor(): string{
		return this.getColor('baColorVibrantBlue');
	}

	editor: Editor = null;
	created(): void{
		this.editor = new Editor({
			editable: !this.disabled,
			content: this.value,
			onUpdate: ({ getHTML }) => {
				this.input(getHTML());
			},
			// onBlur: (...args) => console.log("onBlur", { args }),
			// onDrop: (...args) => console.log("onDrop", { args }),
			// onFocus: (...args) => console.log("onFocus", { args }),
			// onInit: (...args) => console.log("onInit", { args }),
			// onPaste: (...args) => console.log("onPaste", { args }),
			onTransaction: () => {
				this.updateSelection();
			},
			extensions: [
				new Bold(),
				new Italic(),
				new Underline(),
				new Strike(),
				new Link(),
				new Heading(),
				new Blockquote(),
				new History(),
				new ListItem(),
				new BulletList(),
				new OrderedList(),
			]
		});
	}

	showLinkDialog: boolean = false;
	setLink: () => void = null;
	hrefValue: string = "";
	addLink(command: ({href: string}) => void): void{
		console.log("addLink command", command);
		this.hrefValue = "";
		this.showLinkDialog = true;
		this.setLink = () => {
			console.log("set Link", { href: this.hrefValue });
			command({ href: this.hrefValue });
			this.showLinkDialog = false;
		};
	}

	submitSetLink(): void{
		console.log("submitSetLink", { href: this.hrefValue });
		this.setLink();
	}


	beforeDestroy(): void{
		this.editor.destroy();
	}
}
