
import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import { videoEditorStore } from '../../store'
import { formatDurationShort } from '../../helpers/date';
import { MarkupTagClipMuxModel } from '../../models/markup-tag-clip-mux/MarkupTagClipMuxModel';
import { SourceVideoMuxModel } from '../../models/source-video/SourceVideoMuxModel';
import {BAIconsMixin, VuetifyMixin} from '@/mixins';

@Component({
	components: {},
	computed: {}
})
export default class VideoToolbar extends Mixins(BAIconsMixin, VuetifyMixin) {
	volumeValue = 0.2

	get isSVLoaded() {
		return videoEditorStore.loadedSourceVideo && videoEditorStore.loadedSourceVideo.id !== ''
	}

	get isPlaying() {
		return videoEditorStore.isPlaying
	}
	get isMuted() {
		return videoEditorStore.isMuted
	}

	get currentVolume() {
		return videoEditorStore.volume
	}

	get playerCurrentTimeDisplay() {
		return formatDurationShort(videoEditorStore.playerCurrentTime, false)
	}

	get totalTimeDisplay() {
		return formatDurationShort(videoEditorStore.totalTime, false)
	}

	get showCreateClipButton() {
		if (videoEditorStore.playerCurrentTime && videoEditorStore.playerCurrentTime>0.0) {
			return true;
		} else {
			return false;
		}
	}

	startPlaying() {
		videoEditorStore.startPlaying()
	}

	pausePlaying() {
		videoEditorStore.pausePlaying()
	}

	jumpAheadOrBack(amount: number) {
		videoEditorStore.jumpTimeline(amount)
	}

	openANewTagClipDialog() {
		// console.log("Create a new clip here ... just create a blank one for now")
		const theNewClip: MarkupTagClipMuxModel = new MarkupTagClipMuxModel();
		const tempSV: SourceVideoMuxModel = videoEditorStore.loadedSourceVideo;
		// theNewClip.markStart = Math.round(Math.random() * tempSV.videoLengthInSeconds * 100)/100;

		theNewClip.markStart = Math.round(videoEditorStore.playerCurrentTime * 100) / 100;

		theNewClip.fromMuxSourceVideoId = tempSV.id;
		theNewClip.fromMuxVideoPlaybackId = tempSV.muxVideoPlaybackId;
		theNewClip.fromMuxVideoPlaybackUrl = tempSV.muxVideoPlaybackUrl;
		theNewClip.fromMuxVideoAssetId = tempSV.muxVideoAssetId;
		theNewClip.teamEventParentId = tempSV.teamEventParentId;
		theNewClip.teamParentId = tempSV.teamParentId;
		
		theNewClip.dateTagCreated = new Date()
		theNewClip.markDuration = 4.0
		theNewClip.markText = 'Random text'
		theNewClip.screenShotUrl = 'https://image.mux.com/' + theNewClip.fromMuxVideoPlaybackId + '/thumbnail.png?time=' + theNewClip.markStart.toFixed(1).toString()

		// console.log('The MarkupTagClip to be entered is: ', theNewClip);
		videoEditorStore.addMarkupTagClip(theNewClip)
	}

	volumeChange() {
		// console.log('Got slider change and the current value is', this.volumeValue)
		videoEditorStore.setTheVolume(this.volumeValue)
	}

	muteVolumeToggle() {
		videoEditorStore.toggleMute()
	}

	@Watch('videoEditorStore.volume')
	onVolumeValueChanged(val: number, oldVal: number) {
		this.volumeValue = val
	}
}
