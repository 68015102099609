
import { Component, Mixins, Prop } from 'vue-property-decorator';
import VideoClipListItem from './VideoClipListItem.vue';
import { VuetifyMixin, BAIconsMixin } from '../../../mixins';
import { AssignmentModel } from '../../../models/course';
import { MarkupTagClipMuxModel } from '../../../models/markup-tag-clip-mux/MarkupTagClipMuxModel';

@Component({
	components: { VideoClipListItem },
})
export default class VideoClipList extends Mixins(VuetifyMixin, BAIconsMixin){
	@Prop({ default: false }) readOnly: boolean;
  @Prop({ default: () => ([]) }) videoClips: MarkupTagClipMuxModel[];
  
  playButtonClickedOnClip(clipDetails: MarkupTagClipMuxModel) {
  	// console.log("In the VideoClipList - in the Play Button Clicked - clipDetails are: ", clipDetails);
  	this.$emit('playButtonClicked', clipDetails);
  }

}
