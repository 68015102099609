
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin, BAIconsMixin } from '../../mixins';
import { AssignmentModel, RubricModel } from '../../models/course';
import { RubricCriteriaGroup } from '@/../types/interfaces';
import VideoClipList from '../ui/videos/VideoClipList.vue';
import { MarkupTagClipMuxModel } from '../../models/markup-tag-clip-mux/MarkupTagClipMuxModel';

@Component({
	components: { VideoClipList },
})
export default class CriteriaGroupVideoClips extends Mixins(VuetifyMixin, BAIconsMixin){

	@Prop({ default: false }) readOnly: boolean;
	@Prop({ default: 'baColorSuperLightBlue' }) private color: string;
	@Prop() private assignment: AssignmentModel;
	@Prop() group: RubricCriteriaGroup;
	@Prop() private rubric: RubricModel;

	selectedIndex: number = 0;

	get ClipCounts(): number[]{
		return this.group.criteria.map((c, index) => {
			return this.assignment.sourceVideoClips.filter(clip => {
				return clip.markType === this.group.name && clip.markText === this.group.criteria[index].text;
			}).length;
		});
	}
	get SelectedVideoClips(){
		return this.assignment.sourceVideoClips.filter(clip => {
			return clip.markType === this.group.name && clip.markText === this.group.criteria[this.selectedIndex].text;
		});
	}
  
	playButtonClickedOnClip(clipDetails: MarkupTagClipMuxModel) {
		this.$emit('playButtonClicked', clipDetails);
	}

}
