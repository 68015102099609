
import { Component, Mixins, Prop } from 'vue-property-decorator';
import VideoThumbnail from './VideoThumbnail.vue';
import { VuetifyMixin } from '../../../mixins';
import { MarkupTagClipMuxModel } from '../../../models/markup-tag-clip-mux/MarkupTagClipMuxModel';

@Component({
	components: { VideoThumbnail }
})
export default class VideoClipListItem extends Mixins(VuetifyMixin){

	@Prop({ type: Boolean, default: false }) private lastItem: boolean;
	@Prop({ required: true }) private videoClip: MarkupTagClipMuxModel;

	get thumbnailUrl(){
		return this.videoClip.screenShotUrl;
	}
	get VideoClipLength(){
		return `${this.videoClip.markDuration} sec`;
	}
	get VideoClipName(){
		return this.videoClip.markName;
	}
	get VideoClipDescripton(){
		return this.videoClip.additionalText;
	}
  

	thumbnailClick(){
		// console.log("Video Clicked");
		this.$emit('playButtonClicked', this.videoClip);
	}
}
