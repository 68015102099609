
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';
@Component
export default class WhistleSvg extends Mixins(VuetifyMixin){
	@Prop({ type: Number, default: 0.25 }) private opacity: number;
	@Prop({ type: Number, default: 296 }) height: number;
	@Prop({ default: '#34ABE0' }) private color: string;
	@Prop({ default: '#003246' }) private backgroundColor: string;

	get WhistleBodyColor(){
		return this.getColor(this.color);
	}
	get BackgroundColor(){
		return this.getColor(this.backgroundColor);
	}
	get WhistleHandleColor(){
		return this.getColor(this.color);
	}
	get WhistleShadowColor(){
		return '#279FC9';
	}
}
