
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { MarkupTagClipMuxModel } from '../../models/markup-tag-clip-mux/MarkupTagClipMuxModel';
import { RubricModel } from '../../models/course/RubricModel';
import { formatDurationShort } from '../../helpers';
import { videoEditorStore } from '../../store';
import { FormRulesMixin, BAIconsMixin } from '../../mixins';

@Component({
	components: { },
})
export default class VideoEvaluationClipDetailsForm extends Mixins(FormRulesMixin, BAIconsMixin){

	@Prop() theRubric: RubricModel;

	@Prop() value: MarkupTagClipMuxModel;

	@Prop() suggestedName: string;

	@Prop({ default: false }) readOnly: boolean;

	valid = false;

	inEditMode = true;
	
	selectedCriteriaGroup: string = "";
	get CriteriaGroups(): string[]{
		return this.theRubric.criteriaGroup.map(group => group.name);
	}
	selectedSpecificCriteria: string = ""
	get SpecificCriteriaItems(): string[]{
		const criteriaGroup = this.theRubric.criteriaGroup.find(group => group.name === this.selectedCriteriaGroup);
		if(criteriaGroup === undefined) return [];
		return criteriaGroup.criteria.map(c => c.text);
	}

	$refs:{
		form: HTMLFormElement;
	}

	get clipStartTimeFromTimeline(): number {
		return (Math.round(((videoEditorStore.startMarkerPosition / videoEditorStore.playerWidth) * videoEditorStore.totalTime) * 100)) / 100;
	}

	get clipStopTimeFromTimeline(): number {
		return this.clipStartTimeFromTimeline + this.clipDurationFromTimeline;
	}
	
	get clipDurationFromTimeline(): number {
		return (Math.round((((videoEditorStore.stopMarkerPosition - videoEditorStore.startMarkerPosition) / videoEditorStore.playerWidth) * videoEditorStore.totalTime) * 100)) / 100;
	}
	
	formatDurationShort(theDuration: number): string {
		return formatDurationShort(theDuration, true, 's');
	}

	formatStartTime(theStartTime: number): string {
		return formatDurationShort(theStartTime, false);
	}

	async submit($event: Event): Promise<void> {
		$event.preventDefault();
		this.$refs.form.validate()

		this.value.markType = this.selectedCriteriaGroup;
		this.value.markText = this.selectedSpecificCriteria;
		
		if (this.valid) {
			// console.log("It Says the form is valid");
			this.inEditMode = false;
			this.value.markStart = this.clipStartTimeFromTimeline;
			this.value.markDuration = this.clipDurationFromTimeline;
			this.$emit('submit', this.value);

		} else {
			console.log("Looks like the form is not valid");
		}
	}
	
	async cancelForm(): Promise<void> {
		this.$refs.form.reset();
		this.$emit('cancel');
	}

	isCategoryChosen(theCategoryText: string): boolean {
		return this.value.tagKeywords.includes(theCategoryText);
	}

	clickedCategoryChip(theCategoryText: string): void {
		// console.log("In clickedCategoryChip and the param is: ", theCategoryText);
		const tempIdx = this.value.tagKeywords.indexOf(theCategoryText);
		if ( tempIdx >= 0) {
			this.value.tagKeywords.splice(tempIdx, 1);
		} else {
			this.value.tagKeywords.push(theCategoryText);
		}
	}

	criteraSelectionTagChanged(): void{
		this.selectedCriteriaGroup = "";
		this.value.markType = this.selectedSpecificCriteria;
		this.value.markText = this.selectedCriteriaGroup;
	}

	closeFormWindow(): void {
		this.$emit('close');
	}

}
